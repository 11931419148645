@import url(./assets/fonts/Manrope/stylesheet.css);

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --text-color: #fff;
  --text-on-contrast-color: #000536;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px var(--popup-bg) inset !important;
  /* можно изменить на любой вариант цвета */
  -webkit-text-fill-color: var(--popup-text-color) !important;
  background-color: var(--popup-bg) !important;
  background-clip: content-box !important;
  position: relative;
  z-index: 0;
}

p, h1, h2, h3, h4, h5, h6, blockquote, span {
  padding: 0;
  margin: 0;
  color: var(--text-color);
}

p {
  font-family: 'Manrope', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Manrope', sans-serif;
}

a {
  text-decoration: none;
  color: var(--text-color);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


button {
  font-family: 'Manrope', sans-serif;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}