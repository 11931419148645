.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #111859;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    position: relative;
}

.main__img {
    width: 100%;
    max-width: 445px;
    z-index: 1;

}

.main__title {
    color: var(--text-color);
    font-family: 'Manrope';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
    margin-top: 40px;
    max-width: 567px;
    z-index: 1;
}

.main__subtitle {
    color: var(--text-color);
    text-align: center;
    font-family: 'Manrope';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 166.667%;
    margin-top: 15px;
    max-width: 567px;
    z-index: 1;
}

.main__bg {
    position: absolute;
    user-select: none;
    pointer-events: none;
    z-index: 0;
}

.main__bg_pc {
    display: block;
    top: -317px;
    left: -685px;
    max-width: 2792px;
}

.main__bg_mobile {
    display: none;
    top: -65px;
    left: -337px;
    max-width: 1126px;
}

.main__info-button {
    position: fixed;
    bottom: 120px;
    right: 120px;
    border-radius: 600px;
    background: #FFF;
    box-sizing: border-box;
    display: flex;
    width: 80px;
    height: 80px;
    padding: 17px;
    justify-content: center;
    align-items: center;
}

@media (max-width:800px) {
    .main__img {
        max-width: 263px;
    }

    .main__title {
        font-size: 26px;
        line-height: 207.692%;
        margin-top: 84.2px;
        max-width: 317px;
    }

    .main__subtitle {
        text-align: center;
        font-size: 18px;
        line-height: 222.222%;
        margin-top: 10px;
        max-width: 317px;
    }

    .main__bg_pc {
        display: none;
    }

    .main__bg_mobile {
        display: block;
    }

    .main__info-button {
        width: 40px;
        height: 40px;
        padding: 8.5px;
        bottom: 133px;
        right: 20px;
    }
}