@font-face {
    font-family: 'Manrope';
    src: url('Manrope-Bold.eot');
    src: url('Manrope-Bold.eot?#iefix') format('embedded-opentype'),
        url('Manrope-Bold.woff2') format('woff2'),
        url('Manrope-Bold.woff') format('woff'),
        url('Manrope-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('Manrope-SemiBold.eot');
    src: url('Manrope-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Manrope-SemiBold.woff2') format('woff2'),
        url('Manrope-SemiBold.woff') format('woff'),
        url('Manrope-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('Manrope-Medium.eot');
    src: url('Manrope-Medium.eot?#iefix') format('embedded-opentype'),
        url('Manrope-Medium.woff2') format('woff2'),
        url('Manrope-Medium.woff') format('woff'),
        url('Manrope-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

